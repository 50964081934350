// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {},
  reducers: {
    handleLogin: (state, action) => {
      state[config.storageTokenKeyName] = action.payload?.token?.access_token
      localStorage.setItem('uidLink', action.payload.email)
      state[config.storageRefreshTokenKeyName] =
        action.payload?.token?.refresh_token
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload?.token?.access_token
      )
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        action.payload?.token?.refresh_token
      )
    },
    handleLogout: (state) => {
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem(config.storageUserData)
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    handleReset: (state, action) => {
      state[config.storageRefreshTokenKeyName] = action.payload.accessToken
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload.accessToken
      )
    }
  }
})

export const { handleLogin, handleLogout, handleReset } = authSlice.actions

export default authSlice.reducer
