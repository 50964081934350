// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

const defaultAbility = [
  {
    action: 'manage',
    subject: 'all'
  }
]

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleUser: (state, action) => {
      const data = action.payload
      data.ability = defaultAbility
      data.role = data.role_id
      state.userData = data
      localStorage.setItem('userData', JSON.stringify(data))
    }
  }
})

export const { handleUser } = userSlice.actions

export default userSlice.reducer
