// ** Auth Endpoints
export default {
  loginEndpoint: '/v1/auth/login/manual',
  refreshEndpoint: '/v1/auth/refresh',
  resetEndpoint: '/v1/auth/ganti-password',
  firstResetEndpoint: '/v1/auth/ganti-password-sekali',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageUserData: 'userData'
}
