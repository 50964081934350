// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import user from './user'
import { combineReducers } from 'redux'

const combinedReducer = combineReducers({
  auth,
  navbar,
  layout,
  user
})

const rootReducer = (state, action) => {
  if (action.type === 'authentication/handleLogout') {
    state = undefined
  }
  return combinedReducer(state, action)
}

export default rootReducer
